import { Link } from "gatsby";
import styled from "styled-components";

import { p } from "theme/typography";
import { toRem } from "utils/mixins";

export const Wrapper = styled.div`
  padding: ${toRem(10)};
  height: auto;
  margin-bottom: ${toRem(40)};

  &:hover h3,
  &:hover .client {
    opacity: 0.2;
  }
  .text-container {
    cursor: pointer;
    position: relative;
    transition: 400ms;
    z-index: 3;
    color: ${(props) => (props.prlll ? "white" : "")};
  }
  .client {
    margin: 0;
    height: auto;
    min-height: ${toRem(20)};
    margin-top: ${toRem(14)};
    font-size: ${toRem(20)};
    line-height: ${toRem(20)};
    text-align: center;
    margin-bottom: 0;
    transition: 0.4s ease-in-out opacity;
  }
  .h3 {
    margin: 0;
    margin-top: ${toRem(5)};
    //height: ${toRem(56)};
    font-size: ${toRem(28)};
    line-height: ${toRem(28)};
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    transition: 0.4s ease-in-out opacity;
    &--home {
      margin-top: ${toRem(14)};
    }
  }
  p {
    // height: ${toRem(34)};
    margin-top: ${toRem(12)};
    text-align: center;
    margin-bottom: 0;
    transition: 0.4s ease-in-out opacity;
  }
`;

export const VideoContainer = styled.div`
  cursor: pointer;

  * {
    outline: none;
    height: auto;
    margin: 0;
    line-height: 0;
  }

  .slick-prev {
    top: calc(50% - 15.5px);
    left: ${toRem(5)};
    transform: rotate(180deg);
  }

  .slick-next {
    top: calc(50% - 15.5px);
    right: ${toRem(5)};
  }

  .slick-arrow {
    content: "";
    z-index: 2;
    height: ${toRem(31)};
    width: ${toRem(31)};
    // padding: 10px;
    //transform: translateX(10px);
    position: absolute;
    transition: 400ms;
    font-size: 0;
    cursor: pointer;
    background-color: transparent;
    //background-color: red;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${toRem(11)};
    background-image: url("/images/smallarrow.svg");
    opacity: 1;
    &::before {
      display: none;
    }
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
      &:hover {
        opacity: 0.2;
      }
    }
    .slick-disabled {
      opacity: 0.2;
    }
  }
  @media (min-width: 1024px) {
    .slick-arrow {
      opacity: 0;
    }
  }
`;

export const ImageContainer = styled.div`
  cursor: pointer;
  position: relative;
  padding-bottom: ${(props) =>
    props.height ? (props.height / props.width) * 100 + "%" : "56.25%"};
  background: transparent;
  line-height: 0;
  transition: 0.25s ease-in-out opacity;

  .gatsby-image-wrapper {
    left: 0;
    top: 0;
    object-fit: cover;
    position: absolute !important;
    width: 100%;
    height: 100%;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const LongDescription = styled.div`
  // "height: 0" not work with css transitions
  //background-color: red;
  //max-height: ${(props) => (props.show ? toRem(250) : toRem(80))};
  //max-height: ${toRem(200)};
  position: relative;
  //padding-bottom: ${(props) => (props.show ? toRem(18) : toRem(0))};
  p {
    height: unset;
  }
`;

export const MoreLink = styled(Link)`
  width: 100%;
  ${p}
  text-align: center;
  //background-color: rgba(255, 253, 247, 0.7);
  //opacity: ${(props) => (props.show ? 1 : 0)};
  //pointer-events: ${(props) => (props.show ? "" : "none")};
  display: block;
  margin: 0;
  margin-top: 8px;
  text-transform: uppercase;
  padding: 2px;
  text-decoration: underline;
`;
