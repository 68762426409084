import React from "react";

import { graphql } from "gatsby";

import Submenu from "components/submenu";
import Grid from "components/grid";
import HTMLReactParser from "html-react-parser";

const WorkServicesTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: {
        id,
        works_services_page: { worksServiceSelection, introText }
      }
    },
    pageContext: {
      language: { code }
    },
    dimensions,
    isBlack
  } = props;
  //PROPS

  return (
    <>
      <main className="main main--full">
        <h1 className="abs_hide">Work</h1>
        <Submenu isBlack={isBlack} code={code} page={id} />

        {introText && (
          <div
            className="container container--800 withA"
            style={{ paddingBottom: "70px", margin: "0 auto" }}
          >
            {introText && HTMLReactParser(introText)}
          </div>
        )}
        <Grid
          locale={code}
          data={worksServiceSelection}
          hasFilter={false}
          type="works"
          dimensions={dimensions}
        />
      </main>
    </>
  );
};

export const query = graphql`
  query worksser($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      seo {
        seoDescription
        seoTitle
        seoImage {
          localFile {
            url
          }
        }
      }
      uri
      works_services_page {
        introText
        worksServiceSelection {
          ... on WpPost {
            uri
            work_info {
              workType
              title
              shortDescription
              client
              vimeoLink
              availableOnTheSite
              coverImage {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 3600, quality: 100) {
                      ...GatsbyImageSharpFluid
                      src
                    }
                  }
                }
              }
              coverVideo {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default WorkServicesTemplate;
