import styled from "styled-components";

import { h5 } from "theme/typography";
import { white, black } from "theme/colors";
import { toRem } from "utils/mixins";

export const SubmenuContainer = styled.ul`
  z-index: 2;
  position: relative;
  color: ${(props) => (props.white ? white.default : "inherit")};
  h1 {
    text-decoration: underline;
    color: ${(props) => (props.white ? white.default : "inherit")};
    margin-bottom: ${toRem(35)};
  }
  ul {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: ${toRem(28)};
    flex-direction: column;
    li {
      ${h5}
      margin:  ${toRem(5)};
      cursor: pointer;
      a {
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        transition: 250ms;
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: ${toRem(1)};
          display: block;
          margin-top: 0;
          right: 0;
          background-color: ${(props) =>
            props.white ? white.default : black.default};
          transition: 0.4s ease;
        }
        &:hover {
          /* opacity: 0.2; */
          &:after {
            width: 100%;
            left: 0;
          }
        }
      }
      &.active a {
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }
  @media (min-width: 601px) {
    ul {
      flex-direction: row;
      li {
        margin: 0 ${toRem(16)};
      }
    }
  }
`;
