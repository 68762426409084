import React, { useEffect, useState } from "react";
import {
  Wrapper,
  ImageContainer,
  VideoContainer,
  LongDescription,
  MoreLink
} from "./ThumbPost.styles";

import { Link } from "gatsby";
import Img from "gatsby-image";

import VideoPlayer from "../video";
import HTMLReactParser from "html-react-parser";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";

const ThumbPost = (props) => {
  //PROPS
  const {
    data: {
      client,
      coverImage,
      content,
      workType,
      title,
      coverVideo,
      vimeoLink,
      shortDescription
    },
    isNew,
    uri,
    type,
    videoID,
    setVideoID,
    locale
  } = props;
  //PROPS

  //DEFS
  const videos =
    content &&
    content.filter((e) => e.__typename === "WpPost_WorkInfo_Content_Video");

  const isHome = type === "home";
  const isPrlll = type === "prlll";
  const isWorks = type === "works";
  const isTalent = type === "talent";
  const isImage =
    (isNew && props.data.newsType === "photographer") ||
    (!isNew && workType === "photographer");
  const isFilm =
    (isNew && props.data.newsType === "director") ||
    (!isNew && workType === "director");
  //DEFS

  //STATES
  const [isMobile, setMobile] = useState(true);
  //STATES

  //INVIEW
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px"
  });
  //INVIEW

  //USEEFFECT
  useEffect(() => {
    if (props.dimensions?.width < 1299) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [props.dimensions?.width]);
  //USEEFFECT

  return (
    <Wrapper
      ref={ref}
      prlll={isPrlll}
      onMouseEnter={() => {
        isPrlll && props.setColorHover(props.data.projectColor);
      }}
      onMouseLeave={() => {
        isPrlll && props.setColorHover(false);
      }}
    >
      {isFilm && (
        <VideoThumbnail
          isLoad={inView}
          id={vimeoLink}
          isWorks={isMobile ? false : isWorks}
          videos={videos}
          preview={coverVideo?.mediaItemUrl}
          videoID={videoID}
          setVideoID={setVideoID}
          poster={coverImage?.localFile?.childImageSharp?.fluid?.src}
        />
      )}

      {isImage && (
        <>
          {isPrlll ? (
            <Link to={uri} state={{ fromWork: true }} aria-label={title}>
              <ImageThumbnail coverImage={coverImage} inView={inView} />
            </Link>
          ) : (
            <Link to={uri} state={{ fromWork: true }} aria-label={title}>
              <ImageThumbnail coverImage={coverImage} inView={inView} />
            </Link>
          )}
        </>
      )}

      {(isHome || isPrlll) && (
        <div className="text-container">
          <Link to={uri}>
            <p className="h3 h3--home">{title}</p>
          </Link>
          {!!shortDescription && (
            <LongDescription>
              {HTMLReactParser(shortDescription)}
            </LongDescription>
          )}
          <MoreLink to={uri} className="more_link">
            {locale === "PT" ? "Ver +" : "See +"}
          </MoreLink>
        </div>
      )}
      {(isWorks || isTalent) && (
        <Link to={uri} state={{ fromWork: true }} aria-label={title}>
          <div>
            <p className="client">{!!client && client}</p>
            <p className="h3">{title}</p>
          </div>
        </Link>
      )}
    </Wrapper>
  );
};

const VideoThumbnail = (props) => {
  const [cVideo, setVideo] = useState(0);

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 1
  });

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    afterChange: (e) => {
      setVideo(e);
    }
  };

  const {
    isLoad,
    preview,
    videoID,
    setVideoID,
    id,
    videos,
    isWorks,
    poster
  } = props;

  return (
    <VideoContainer ref={ref}>
      {!!videos && videos[1] ? (
        <Slider {...settings}>
          {videos.map((video, index) => {
            return (
              <div key={index}>
                <VideoPlayer
                  poster={poster}
                  isLoad={isLoad}
                  isMulti={true}
                  videoIndex={index}
                  slideIndex={cVideo}
                  onHover={isWorks}
                  isIn={inView}
                  id={video.vimeo}
                  preview={video?.previewVideo?.mediaItemUrl}
                  videoID={videoID}
                  setVideoID={setVideoID}
                />
              </div>
            );
          })}
        </Slider>
      ) : (
        <VideoPlayer
          poster={poster}
          isLoad={isLoad}
          isMulti={false}
          videoIndex={null}
          slideIndex={null}
          onHover={isWorks}
          isIn={inView}
          id={id}
          preview={preview}
          videoID={videoID}
          setVideoID={setVideoID}
        />
      )}
    </VideoContainer>
  );
};

const ImageThumbnail = (props) => {
  const { coverImage, inView } = props;
  let image = coverImage?.localFile?.childImageSharp.fluid;
  return (
    <ImageContainer
      height={coverImage?.mediaDetails?.height}
      width={coverImage?.mediaDetails?.width}
    >
      {inView ? <Img fluid={image} alt={coverImage?.altText} /> : null}
    </ImageContainer>
  );
};

export default ThumbPost;
