import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import { SubmenuContainer } from "./Submenu.styles";

const Submenu = (props) => {
  //PROPS
  const { code, page } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query Submenu {
      allWpMenu {
        edges {
          node {
            menuItems {
              nodes {
                label
                url
                connectedNode {
                  node {
                    id
                  }
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  const {
    allWpMenu: { edges }
  } = data;
  //DATA

  //DEFS
  let info = edges.filter((item) =>
    item.node.name.search("Services Menu Lang:" + code) !== -1 ? item : null
  );

  let menu = info[0].node.menuItems.nodes;
  //DEFS

  return (
    <SubmenuContainer white={props.white}>
      <h1 className="h1-underline"> {code === "PT" ? "SERVICE PRODUCTION" : "SERVICE PRODUCTION"}</h1>
      <ul>
        {menu.map((item, index) => {
          return (
            <li
              key={index}
              className={page === item.connectedNode.node.id ? "active" : ""}
            >
              <Link to={item.url}>{item.label}</Link>
            </li>
          );
        })}
      </ul>
    </SubmenuContainer>
  );
};

export default Submenu;
