import styled from "styled-components";

import { ContainerSize } from "theme/sizes";

export const Wrapper = styled.div`
  overflow-y: hidden;
  ${ContainerSize}
  height: auto;
  padding: ${(props) => (props.isHome ? "0 0 70px" : "")};
  min-height: ${(props) =>
    props.style ? "unset" : props.isHome ? "100vh" : ""};

  @media (min-width: 1079px) {
    padding: ${(props) => (props.isHome ? "70px 0" : "")};
  }
`;
