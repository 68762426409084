import React, { useEffect, useState } from "react";

import StackGrid from "react-stack-grid";

import { Wrapper } from "./Grid.styles";
import ThumbPost from "components/thumbPost";

const Grid = (props) => {
  //PROPS
  const { locale, data, hasFilter, type } = props;
  //PROPS

  //DEFS
  const subFilter = props.subFilter;
  const workType = props.workType;
  const skills = props.skills;
  const setColorHover = props.setColorHover;
  const photoId = ["dGVybToxMg==", "dGVybToxOA=="];
  const filmId = ["dGVybToxNg==", "dGVybToxNA=="];
  //DEFS

  //STATES
  const [gridWidth, setGridWidth] = useState(4);
  const [videoID, setVideoID] = useState(null);
  const [renderHook, setRenderHook] = useState(true);
  //STATES

  //EFFECTS
  useEffect(() => {
    let isMobile = window.innerWidth < 600;

    let isTablet = window.innerWidth < 969;

    let isFour = type === "home" || type === "works";

    let isMulti = props?.skills?.length > 1 ? true : false;

    let isUni = props?.skills?.length === 1 ? true : false;

    let isPhoto = isUni && skills[0] === "photographer";

    let isFilm = isUni && skills[0] === "director";

    let isFilterAndPhoto = subFilter === "photographer";

    let isFilterAndFilm = subFilter === "director";

    if (isMobile) {
      setGridWidth("100%");
    } else if (isTablet) {
      setGridWidth("50%");
    } else if (isPhoto) {
      setGridWidth("33%");
    } else if (isFilm) {
      setGridWidth("50%");
    } else if (isFour) {
      if (window.innerWidth > 1280) {
        setGridWidth("25%");
      } else {
        setGridWidth("33%");
      }
    } else if (isMulti && isFilterAndFilm) {
      setGridWidth("50%");
    } else if (isMulti && isFilterAndPhoto) {
      setGridWidth("33%");
    } else {
      setGridWidth("25%");
    }
  }, [subFilter, data, skills, type, props.dimensions?.width]);

  useEffect(() => {
    setTimeout(() => {
      setRenderHook(!renderHook);
    }, 150);
  }, [gridWidth]);
  //EFFECTS

  return (
    <Wrapper
      id="grid"
      works={props.works}
      isHome={props.type === "home" ? true : false}
      nomin={props.style === "nomin" ? true : false}
    >
      <StackGrid
        gutterWidth={0}
        gutterHeight={0}
        columnWidth={gridWidth}
        duration={0}
        monitorImagesLoaded={true}
      >
        {data &&
          data.map((item, index) => {
            let isAvailableW = item?.work_info?.availableOnTheSite;
            let isAvailableN = item?.news_info?.availableOnTheSite;
            let categories = item?.categories?.nodes.map((e) => e.name);
            let isWorkType =
              categories?.includes(workType) || !workType ? true : false;

            if (
              hasFilter &&
              subFilter !== item.work_info.workType &&
              subFilter
            ) {
              return null;
            }

            return (isAvailableW || isAvailableN) && isWorkType ? (
              <ThumbPost
                dimensions={props.dimensions}
                setColorHover={setColorHover}
                key={index}
                type={type}
                index={index}
                renderHook={renderHook}
                setRenderHook={setRenderHook}
                isNew={isAvailableN}
                data={isAvailableN ? item.news_info : item.work_info}
                uri={item.uri}
                locale={locale}
                videoID={videoID}
                setVideoID={setVideoID}
              />
            ) : null;
          })}
      </StackGrid>
    </Wrapper>
  );
};

export default Grid;
